<template>
	<portal
		to="stackedOnTopModal"
		v-if="getIsVisible"
	>
		<modal-inner
			@close="handleClose"
			size="sm"
			heading="Claim"
		>
			<div data-component="claim-remaining-tricks-modal">
				<div data-element="modal-actions">
					<validation-observer
						data-element="form"
						tag="form"
						ref="form"
						@submit.prevent="handleClaim"
					>
						<input-field
							name="Enter the REMAINING number of tricks you are claiming"
							type="number"
							:rules="getRules"
							:value="tricks"
							@input="handleInputChange"
						/>
						<actions
							:actions="getActions"
							:isWaiting="isWaiting"
							@actionClick="onActionClick"
						/>
					</validation-observer>
				</div>
			</div>
		</modal-inner>
	</portal>
</template>
<script>

	import ModalInner from '@/components/ui/modal/ModalInner';
	import InputField from '@/components/forms/InputField';
	import Actions from '@/components/ui/Actions';
	import actionClick from '@/mixins/actionClick';

	export default {
		components: {
			ModalInner,
			InputField,
			Actions
		},
		mixins: [actionClick],
		data () {
			return {
				tricks: 0,
				isWaiting: undefined,
				claimFailed: false
			};
		},
		computed: {
			getIsVisible () {
				return this.$store.getters['simulator/getClaimingTricksModalIsVisible'];
			},
			getRules () {
				return {
					required: true,
					canClaim: {
						isCanClaim: !this.claimFailed,
						validationMessage: 'Claim is declined'
					}
				};
			},
			getActions () {
				return {
					primary: [
						{
							text: 'Claim',
							type: 'submit',
							btnId: 'claim'
						}
					],
					secondary: [
						{
							text: 'Cancel',
							actionId: 'handleClose'
						}
					]
				};
			}
		},
		methods: {
			handleClose () {
				this.tricks = 0;
				this.$store.commit('simulator/setClaimingTricksModalIsVisible', false);
			},
			handleInputChange (value) {
				this.tricks = value;
				this.claimFailed = false;
			},
			async handleClaim () {
				const valid = await this.$refs.form.validate();
				if (!valid) {
					return;
				}
				try {
					this.isWaiting = 'claim';
					const result = await this.$store.dispatch(
						'simulator/claimRemainingTricks',
						{ tricks: this.tricks }
					);
					if (result) {
						this.handleClose();
					} else {
						this.claimFailed = true;
					}
				} catch (error) {
					this.claimFailed = false;
					console.error(error);
				} finally {
					this.isWaiting = undefined;
				}
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='claim-remaining-tricks-modal'] {
		width: 100%;
		height: 100%;
		[data-element='form'] {
			margin-bottom: 0;
			[data-component='input-field'] {
				margin-top: 0;
			}
			[data-component='actions'] {
				padding: 0;
			}
		}
	}

</style>
